.modal-backdrop {
  body.modal-open {
    overflow: auto !important;
    padding: 0px !important;
  }

  background-color: var(--text-color);
  opacity: 0.65 !important;
}
.modal-header {
  padding: 1.25rem;
}
.modal-header .close {
  font-size: 32px;
  padding: 0.5rem 0.5em !important;
  color: var(--black);
  font-weight: 400;
}
.modal-footer {
  color: var(--text-color);
  background-color: var(--background-color);
  border-top: 1px solid var(--light-grey) !important;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.modal-body {
  padding: 1.25rem;
}
.modal-content {
  border-radius: 12px;
}
.wallet-connect-block {
  display: grid;
  gap: 10px;

  button {
    outline: none;
    box-shadow: none;
  }
}
.wallet-connect-block.grid { 
  display: grid; 
  grid-gap: 10px; 
  grid-template-columns: auto auto; 
  gap: 10px; 
  .wallect-connect-btn{ 
    height: 100px; 
    outline: none; 
    box-shadow: none; 
    display: flex; 
    flex-direction: column-reverse; 
  } 
} 
.wallect-connect-btn {
  background-color: rgb(237, 238, 242);
  outline: none;
  border: 1px solid rgb(206, 208, 217);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  margin-top: 0px;
  width: 100%;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.wallect-connect-btn:hover {
  border-color: var(--grey);
}
.wallet-name {
  display: flex;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}
.wallet-name h4 {
  font-size: 1.1rem;
  font-weight: 400;
  text-align: left;
}
.wallet-name h4 span {
  font-size: 0.9rem;
}
.wallet-icon-block h5 {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
}
.wallet-icon-block p {
  font-size: 0.8rem;
  margin: 0px;
  padding: 0px;
}
.wallet-icon {
  display: flex;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.wallet-icon img {
  height: 34px;
  width: 34px;
}
button.back {
  padding: 6px 0px;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
button.back:hover {
  color: var(--text-color);
  opacity: 1;
}
.back {
  float: right;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: var(--text-color);
  text-shadow: none;
  opacity: 0.6;
}
